@import "variables";

.page--default .q-field {
  margin: 20px 0;
  position: relative;
  padding: 10px; }

.q-editor-item .q-field {
  margin: 10px 0; }

.page--default .q-fieldset .q-field {
    margin: 5px 0; }

.q-field--footnote {
  margin-bottom: 0;
  padding-bottom: 0;
  .q-field + & {
    margin-top: 40px; } }

.page--default .q-field-label {
  text-align: left;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: .3px;
  display: block;
  margin: 0 0 20px 0;
  cursor: pointer;
  color: darken($fontColor, 2%); }

.page--default .q-field-label + .q-field-description {
  margin-top: -10px; }

.page--default .q-fieldset .q-field-label {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 10px 0;
  & + .q-field-description {
    margin-top: 0; } }


//
// Frame view
//

.page--frame .survey-form-fields {
  margin: 0 -10px;
  @media screen and (max-width: 600px) {
    margin: 0; } }

.page--frame .q-field {
  margin: 0 0 15px;
  position: relative;
  padding: 10px;
  .q-fieldset & {
    margin: 5px 0; }
  @media screen and (max-width: 600px) {
    padding: 0; } }

.page--frame .q-field--fieldset {
  padding: 0; }

.page--frame .q-field-label {
  display: block;
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 10px 0;
  & + .q-field-description {
    margin-top: 0; } }




.q-field-label-required {
  margin-left: 5px;
  color: $requiredColor;
  // .q-field-label:hover &
 }  //   color: $yellow

.q-field-description {
  margin-bottom: 20px;
  color: #555;
  font-size: 16px;
  line-height: 20px; }

/* Errors */

.page--default .q-field--error {
  box-shadow: 0 0 0 3px rgba($red, .5); }

.q-field-errors {
  position: absolute;
  top: 100%;
  right: 0;
  margin-right: 5px;
  z-index: 10;
  display: block;
  background: $red;
  border-radius: 2px;
  pointer-events: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  margin-top: -12px; }

.q-field-errors:after {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 6px;
  margin-top: -5px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid $red; }

.q-field-error {
  padding: 0 12px;
  line-height: 26px;
  font-size: 14px;
  color: #fff; }

.q-fieldset .q-field--public_consent {
  display: flex;
  .q-field-input--public_consent {
    margin: auto; } }


/* Input */
