@import "variables";

.q-comment-text {
  background: rgba(#fff, .95);
  textarea {
    position: relative;
    z-index: 4; } }

.q-comment-text-input-user {
  display: flex;
  position: relative;
  z-index: 3;
  &:after {
    content: " ";
    display: block;
    width: 10px;
    height: 10px;
    background: $borderLightGrey;
    transform: rotate(45deg);
    position: absolute;
    bottom: calc(100% - 5px);
    left: 18px;
    // .q-comment-text textarea:focus + &
 } }    //   background: $orange

.q-comment-text-input-user-icon {
  color: #fff;
  background: $orange;
  width: 26px;
  height: 26px;
  line-height: 26px;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
  margin-left: 10px; }

.q-comment-text-input-user-image img {
  color: #fff;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-top: 10px;
  margin-left: 10px;
  object-fit: cover; }

.q-comment-text-input-user-info {
  line-height: 18px;
  margin-top: 14px;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500; }
