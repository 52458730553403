//
// Default view
//

.page--default .form-item {
  margin: 20px 0;
  position: relative;
  padding: 10px; }

.page--default .form-fieldset .form-item {
    margin: 5px 0; }

.form-item--footnote {
  margin-bottom: 0;
  padding-bottom: 0;
  .form-item + & {
    margin-top: 40px; } }

.page--default .form-item-label {
  text-align: left;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: .3px;
  display: block;
  margin: 0 0 20px 0;
  cursor: pointer;
  color: darken($fontColor, 2%); }

.page--default .form-item-label + .form-item-description {
  margin-top: -10px; }

.page--default .form-fieldset .form-item-label {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 10px 0;
  & + .form-item-description {
    margin-top: 0; } }


//
// Frame view
//

.page--frame .survey-form-fields {
  margin: 0 -10px;
  @media screen and (max-width: 600px) {
    margin: 0; } }

.page--frame .form-item {
  margin: 0 0 15px;
  position: relative;
  padding: 10px;
  .form-fieldset & {
    margin: 5px 0; }
  @media screen and (max-width: 600px) {
    padding: 0; } }

.page--frame .form-item--fieldset {
  padding: 0; }

.page--frame .form-item-label {
  display: block;
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 10px 0;
  & + .form-item-description {
    margin-top: 0; } }




.form-item-label-required {
  margin-left: 5px;
  color: $requiredColor;
  // .form-item-label:hover &
 }  //   color: $yellow

.form-item-description {
  margin-bottom: 20px;
  color: #555; }

/* Errors */

.page--default .form-item--error {
  box-shadow: 0 0 0 3px rgba($red, .5); }

.form-item-errors {
  position: absolute;
  top: 100%;
  right: 0;
  margin-right: 5px;
  z-index: 10;
  display: block;
  background: $red;
  border-radius: 2px;
  pointer-events: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  margin-top: -12px; }

.form-item-errors:after {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 6px;
  margin-top: -5px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid $red; }

.form-item-error {
  padding: 0 12px;
  line-height: 26px;
  font-size: 14px;
  color: #fff; }

.form-fieldset .form-item--public_consent {
  display: flex;
  .form-item-input--public_consent {
    margin: auto; } }
