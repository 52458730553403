.form-field-file {
  display: block;
  height: 42px;
  border: 2px solid $borderLightGrey;
  border-radius: 2px;
  &:hover {
    border-color: $borderGrey; } }

.form-field-file-upload {
  position: relative;
  height: 38px;
  input {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer; } }

.form-field-file-upload-text {
  display: inline-block;
  line-height: 26px;
  padding: 0 10px;
  color: $fontColor;
  font-size: 12px;
  font-weight: 500;
  background: $lightGrey;
  border: 1px solid $borderLightGrey;
  border-radius: 2px;
  margin: 5px;
  i {
    margin-right: 6px;
    opacity: .6; } }

.form-field-file-upload-info {
  opacity: .8;
  font-size: 10px; }


.form-field-file-item {
  display: flex;
  height: 38px; }

.form-field-file-item-preview {
  width: 38px;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 5px;
  img {
    display: block;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    object-fit: cover; } }

.form-field-file-item-name {
  flex-grow: 1;
  line-height: 20px;
  padding: 8px;
  font-size: 14px;
  font-weight: 500; }

.form-field-file-item-name-size {
  font-size: 10px;
  opacity: .5; }

.form-field-file-item-remove {
  width: 38px;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 5px;
  button {
    background: $lightGrey;
    border: 1px solid $borderLightGrey;
    padding: 0;
    width: 28px;
    height: 28px;
    border-radius: 2px;
    line-height: 26px;
    color: $borderGrey;
    font-size: 10px;
    &:hover {
      color: $red;
      border-color: $red; }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $orange; } } }

