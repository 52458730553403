@import "variables";

.q-content-body {
  color: $fontColor;
  h1 {
    font-size: 30px;
    line-height: 1.2;
    letter-spacing: .3px;
    margin: 0 0 15px; }
  h2 {
    font-size: 24px;
    line-height: 1.2;
    letter-spacing: .3px;
    margin: 0 0 15px; }
  h3 {
    font-size: 20px;
    margin: 0 0 15px; }
  p {
    margin: 0 0 0.7em;
    line-height: 1.3; } }

.q-content-body ol {
  margin: 10px 0 15px;
  padding: 0 0 0 20px;
  li {
    line-height: 20px;
    margin-bottom: 4px; } }

.q-content-body ul {
  margin: 10px 0 15px;
  padding: 0;
  li {
    counter-increment: li-count;
    list-style: none;
    position: relative;
    padding-left: 20px;
    line-height: 20px;
    margin-bottom: 4px;
    &:before {
      color: $yellow;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      line-height: 20px;
      height: 20px;
      width: 20px; } } }

.q-content-body {
  li.ql-indent-1 {
    margin-left: 20px; }
  li.ql-indent-2 {
    margin-left: 35px; }
  li.ql-indent-3 {
    margin-left: 50px; } }


.q-content-body ul li:before {
  content: "\f054";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 12px;
  text-align: center; }
