@import "variables";


.page--default .survey-form-submit {
  text-align: center;
  background: $tmblue;
  color: #fff;
  padding: 30px; }

.page--default .survey-form-submit-button {
  width: 220px;
  height: 50px;
  display: block;
  margin: 0 auto;
  border-radius: 30px;
  border: 1px solid #fff;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: $tmblue;
  position: relative;
  i.fa {
    position: absolute;
    top: 16px;
    left: 16px; }
  &:hover {
    opacity: .8; }
  &:focus {
    outline: none;
    box-shadow: 0 0 2px 2px rgba(#159ce4, .7); }
  &[disabled] {
    opacity: .8;
    &, &:hover, &:focus {
      color: $tmblue;
      background: #fff; } } }

.page--frame .survey-form-submit {
  text-align: center; }

.page--frame .survey-form-submit-button {
  display: inline-block;
  margin: 0;
  border-radius: 20px;
  border: 1px solid $blue;
  background-color: $blue;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  position: relative;
  padding: 8px 18px;
  i.fa {
    font-size: 12px;
    margin-right: 8px;
    margin-left: -4px; }
  &:hover {
    color: #fff;
    opacity: .8; }
  &:focus {
    outline: none;
    box-shadow: 0 0 2px 2px rgba(#159ce4, .7); }
  &[disabled] {
    opacity: .8;
    &, &:hover, &:focus {
      color: $tmblue;
      background: #fff; } } }
