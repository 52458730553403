@import "variables";

.q-video-field {
  display: flex;
  border-radius: 3px;
  box-shadow: 0 2px 10px rgba(#000, .1);
  @media screen and (max-width: 750px) {
    display: block; } }

.q-video-field-video {
  width: 400px;
  height: 300px;
  background: #333;
  flex: 0 0 400px;
  border-radius: 3px 0 0 3px;
  display: flex;
  @media screen and (max-width: 750px) {
    width: 100%;
    border-radius: 3px 3px 0 0; } }

.q-video-field-video--capture {}

.q-video-field-video--preview {
  video {
    display: block;
    width: 100%;
    height: 100%;
    flex: 1;
    object-fit: contain;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $yellow; } } }

.q-video-field-action {
  flex: 1;
  border: 1px solid $borderLighterGrey;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  display: flex;
  flex-direction: column; }

.q-video-field-action-buttons {
  padding: 0 20px 20px;
  @media screen and (max-width: 750px) {
    text-align: center; } }

.q-video-field-button {
  display: inline-block;
  background: none;
  border: none;
  padding: 0 6px;
  line-height: 20px;
  border-radius: 10px;
  color: $fontColorLight;
  font-size: 14px;
  i.fa {
    margin-right: 6px;
    font-size: 12px; }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; }
  &:hover {
    i.fa {
      color: tomato; } } }

.q-video-field-content {
  padding: 20px;
  color: $fontColor;
  h3 {
    font-size: 16px;
    margin: 0 0 5px;
    i.fa {
      margin-right: 6px;
      color: $green; } }
  p {
    margin: 0 0 10px;
    &:last-child {
      margin: 0; } }
  ul, ol {
    margin: 10px 0 0;
    padding: 0;
    list-style: none;
    li {
      margin-bottom: 5px;
      position: relative;
      padding-left: 14px;
      line-height: 20px;
      &:before {
        content: "-";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 14px;
        text-align: left;
        color: $fontColorLight;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px; } }
    & + h3 {
      margin-top: 20px; } }
  ol {
    counter-reset: list-counter;
    li {
      counter-increment: list-counter;
      padding-left: 20px;
      &:before {
        content: counter(list-counter) ".";
        font-size: 12px;
        font-weight: 500;
        width: 20px;
        text-align: center;
        padding-top: 2px; } } } }

.q-video-field-video-upload {
  display: flex;
  flex: 1; }

.q-video-field-video-upload-button {
  position: relative;
  margin: auto;
  display: inline-block;
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer; } }

.q-video-field-video-upload-button span {
  @extend .q-capture-action; }

.q-capture-action + .q-video-field-video-upload {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px dashed rgba(#fff, .1);
  .q-video-field-video-upload-button {
    display: inline-block;
    span {
      color: #fff;
      background: none;
      border-color: rgba(#fff, .6); } } }

.q-capture-action + .q-video-field-video-upload .q-video-field-video-upload-button:hover span {
  text-decoration: underline #999; }

.q-capture-wrapper,
.q-capture-media-wrapper > div,
.q-capture-media-wrapper {
  display: flex;
  flex: 1; }

.q-capture-device-selectors {
  position: absolute;
  width: 100%;
  z-index: 2; }

.q-capture-actions {
  margin: auto 0;
  width: 100%;
  padding: 20px;
  text-align: center;
  .survey-error {
    padding: 0 0 20px; } }

.q-capture-action {
  display: inline-block;
  border: none;
  background: #fff;
  border: 1px solid #fff;
  box-shadow: 0 2px 10px rgba(#000, .1);
  line-height: 20px;
  padding: 9px 15px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 500;
  color: $fontColor;
  text-align: center;
  i.fa {
    margin-right: 10px;
    font-size: 16px; }
  &:focus {
    outline: none; } }

.q-capture-button-wrapper {
  display: inline-block;
  margin-right: 10px; }

.q-capture-button {
  font-size: 14px;
  display: inline-block;
  border: 1px solid $borderLighterGrey;
  padding: 6px 12px;
  line-height: 20px;
  border-radius: 20px;
  background: #fff;
  i.fa {
    margin-right: 10px;
    font-size: 12px;
    color: $fontColorLight; }
  &:hover {
    border-color: $orange;
    i.fa {
      color: $orange; } }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; }
  @media screen and (max-width: 330px) {
    font-size: 12px;
    font-weight: 500;
    i.fa {
      font-size: 10px; } } }

.q-capture-media {
  position: relative;
  background: #333;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  flex: 1;
  video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain; } }

.q-capture-media-action {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff; }

.q-capture-media-action--record {
  background: rgba(#000, .5); }

.q-capture-media-action--counting {
  background: none; }

.q-capture-media-action--permission {
  display: flex; }

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 2px rgba($red, .5); }
  100% {
    box-shadow: 0 0 0 20px rgba($red, 0); } }

.q-capture-media-action-button {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 15px;
  button {
    display: block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 50%;
    border: none;
    background: $red;
    padding: 0;
    margin: 0 auto;
    position: relative;
    &:hover {
      box-shadow: 0 0 0 6px rgba($red, .2); }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 6px rgba($red, .2); }
    .q-capture-media-action--stop & {
      background: #fff;
      color: $red;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 50%;
        animation: ripple 3s linear infinite 1s; }
      &:after {
        animation: ripple 3s linear infinite 2s; } }
    @media screen and (max-width: 400px) {
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 12px; } } }

.q-capture-media-countdown {
  font-size: 24px;
  font-weight: 700; }

.q-capture-media-action-title {
  font-size: 20px;
  line-height: 22px;
  font-weight: 300;
  margin: auto;
  text-align: center;
  padding: 20px;
  @media screen and (max-width: 400px) {
    font-size: 20px;
    line-height: 26px; } }

.q-capture-media-close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 3;
  button {
    display: block;
    height: 24px;
    width: 24px;
    border: none;
    border-radius: 50%;
    color: #fff;
    background: $red;
    font-size: 12px;
    margin: 0;
    padding: 0;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px #fff; }
    &:hover {
      background: #fff;
      color: $blue; } } }

.q-capture-media-error {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: tomato;
  color: #fff;
  display: flex;
  opacity: .8; }

.q-capture-media-error-inner {
  margin: auto;
  font-size: 18px; }

.q-capture-deviceswitcher {}
// border-bottom: 1px solid $borderLighterGrey
//height: 40px

.q-capture-deviceswitcher-select {
  float: right;
  position: relative;
  button {
    display: block;
    border: none;
    background: none;
    text-align: center;
    width: 100%;
    padding: 10px 10px;
    line-height: 20px;
    font-size: 14px;
    min-width: 120px;
    &:focus {
      outline: none;
      box-shadow: inset 0 0 0 2px $yellow; }
    i.fa {
      font-size: 12px;
      margin-right: 4px; } } }

.q-capture-deviceswitcher-select button {
  color: rgba(#000, .8);
  i.fa {
    color: rgba(#000, .6); }
  &:hover,
  &:hover i.fa {
    color: rgba(#000, .8); }
  .q-capture-deviceswitcher--dark & {
    color: rgba(#fff, .8);
    i.fa {
      color: rgba(#fff, .6); }
    &:hover,
    &:hover i.fa {
      color: rgba(#fff, .8); } } }

.q-capture-deviceswitcher-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3; }

.q-capture-deviceswitcher .q-capture-deviceswitcher-menu,
.q-capture-deviceswitcher--dark .q-capture-deviceswitcher-menu {
  position: absolute;
  z-index: 4;
  top: 100%;
  right: 0;
  background: #fff;
  border: 1px solid $borderLightGrey;
  box-shadow: 0 3px 8px rgba(#000, .1);
  &:after {
    content: "";
    border: 6px solid transparent;
    border-bottom-color: $borderLightGrey;
    position: absolute;
    right: 50%;
    bottom: 100%;
    margin-left: -3px; }
  button {
    padding: 7px 10px;
    border-bottom: 1px solid $borderLighterGrey;
    color: $fontColor;
    &:last-child {
      border: none; }
    &:hover {
      background: darken(#fff, 2%);
      color: $fontColor; } } }
