@import "variables";

.page--default {
  .survey-form-inner,
  .survey-thanks-inner {
    padding: 30px 20px 20px 20px;
    max-width: 820px;
    margin: 0 auto;
    @media screen and (max-width: 600px) {
      padding: 10px; } } }

.page--frame {
  .survey-form {
    padding: 4px; } }

.survey-form-header,
.survey-thanks-header {
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 1px solid $borderLighterGrey;
  margin: 0 10px 20px;
  .page--frame & {
    display: none; } }

.survey-form-header-logo,
.survey-thanks-header-logo {
  img {
    border: 0;
    display: block;
    margin: 0 auto;
    width: 160px;
    height: 80px;
    object-fit: contain;
    @media screen and (max-width: 330px) {
      width: 100%;
      height: 100px; } } }

.survey-form-header-name,
.survey-thanks-header-name {
  font-size: 28px;
  font-weight: 300;
  display: inline-block;
  margin: 20px auto 10px; }
