@import "variables";

.q-fieldset-fields {
  display: flex;
  flex-wrap: wrap; }

.page--default .q-fieldset {
  margin: 0 -10px; }

.q-fieldset--columns-1 .q-fieldset-fields > .q-item,
.q-fieldset--columns-1 .q-fieldset-fields > .q-field,
.q-fieldset--columns-1 .q-fieldset-fields > .q-editor-item {
  width: 100%; }

.q-fieldset--columns-2 .q-fieldset-fields > .q-item,
.q-fieldset--columns-2 .q-fieldset-fields > .q-field,
.q-fieldset--columns-2 .q-fieldset-fields > .q-editor-item {
  width: 50%; }

.q-fieldset--columns-3 .q-fieldset-fields > .q-item,
.q-fieldset--columns-3 .q-fieldset-fields > .q-field,
.q-fieldset--columns-3 .q-fieldset-fields > .q-editor-item {
  width: 33.33%; }

.q-fieldset--columns-4 .q-fieldset-fields > .q-item,
.q-fieldset--columns-4 .q-fieldset-fields > .q-field,
.q-fieldset--columns-4 .q-fieldset-fields > .q-editor-item {
  width: 25%; }

.q-fieldset--columns-5 .q-fieldset-fields > .q-item,
.q-fieldset--columns-5 .q-fieldset-fields > .q-field,
.q-fieldset--columns-5 .q-fieldset-fields > .q-editor-item {
  width: 20%; }


.q-fieldset {}

.q-fieldset--columns-2,
.q-fieldset--columns-3,
.q-fieldset--columns-4,
.q-fieldset--columns-5 {
  .q-item--privacy_policy {
    padding-top: 30px; }
  @media screen and (max-width: 600px) {
    .q-fieldset-fields > {
      .q-item,
      .q-field,
      .q-editor-item {
        width: 100%; }
      .q-item--privacy_policy {
        padding-top: 0; } } } }

