*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit; }

html,
body, {
  margin: 0;
  padding: 0;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif; }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

a {
  color: $blue; }

body.view-default {
  background-color: #f4f4f4; }

.page--default {
  padding: 20px 0 50px;
  @media screen and (max-width: 600px) {
    padding: 0; }
  .page-container {
    max-width: 960px;
    margin: 0 auto;
    height: 100%; } }

.page--default .page-inner {
  background: #fff;
  box-shadow: 0 2px 6px rgba(#000, .05);
  @media screen and (min-width: 600px) {
    margin-top: 40px; } }

.page--default .survey-langswitcher + .page-inner {
  margin-top: 0; }

.page--frame {
  .page-container {
    max-width: 960px;
    margin: 0 auto;
    height: 100%; } }

.page-footer {
  text-align: center;
  padding: 30px 30px 20px;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  .page--frame & {
    display: none; } }

.page-footer-inner {
  @media screen and (min-width: 600px) {
    display: flex;
    justify-content: center;
    align-items: center; } }

.page-footer-text {
  color: rgba(#000, .6);
  font-weight: 500;
  font-size: 14px;
  padding: 0 0 6px;
  @media screen and (min-width: 600px) {
    padding: 0 8px 0 0;
    position: relative;
    top: -2px; }
  .page-footer--dark & {
    color: rgba(#fff, .6); } }

.page-footer-logo {
  img {
    max-width: 120px;
    height: 22px;
    .page-footer--light & {
      opacity: .5; } } }

.page-footer-info {
  margin-top: 10px;
  font-size: 12px;
  line-height: 18px; }


.page-footer-info,
.page-footer-info a,
.page-footer-info a:hover {
  color: rgba(#000, .4);
  .page-footer--dark & {
    color: rgba(#fff, .4); } }
