.form-field--radios {}

.form-radio {
  display: flex;
  width: 100%;
  background: none;
  border: none;
  padding: 0;
  text-align: left;
  &:focus {
    outline: none; } }

.form-radio-icon-wrapper {
  @extend .form-checkbox-icon-wrapper; }

.form-radio-icon {
  @extend .form-checkbox-icon;
  border-radius: 15px; }

.form-radio-label {
  @extend .form-checkbox-label; }
