@import "variables";


.survey-thanks-content {
  text-align: center;
  font-size: 18px;
  color: #364155; }

.survey-thanks-content-message {
  padding: 20px 20px 60px;
  h2 {
    font-size: 30px;
    line-height: 1.2;
    letter-spacing: 1px; }
  p {
    margin: 0; }
  img {
    display: block;
    max-width: 100%;
    margin: 1em auto;
    height: auto; } }

.survey-thanks-greetings {
  margin-top: 2em;
  opacity: .5; }

.survey-thanks-content-from {
  padding: 40px 20px 20px; }

// Frame thanks

.frame-thanks {
  text-align: center;
  padding: 20px;
  background: #f4f4f4;
  border-radius: 3px; }

.frame-thanks h2 {
  margin: 0 0 1em;
  font-size: 1.2em;
  font-weight: 500; }

.frame-thanks-content {}
