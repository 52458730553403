.form-fieldset-fields {
  display: flex;
  flex-wrap: wrap; }

.page--default .form-fieldset {
  margin: 0 -10px; }

.form-fieldset--columns-1 .form-item {
  width: 100%; }

.form-fieldset--columns-2 .form-item {
  width: 50%; }

.form-fieldset--columns-3 .form-item {
  width: 33.33%; }

.form-fieldset--columns-4 .form-item {
  width: 25%; }

.form-fieldset--columns-5 .form-item {
  width: 20%; }


.form-fieldset {}

.form-fieldset--columns-2,
.form-fieldset--columns-3,
.form-fieldset--columns-4,
.form-fieldset--columns-5 {
  .form-item--privacy_policy {
    padding-top: 30px; }
  @media screen and (max-width: 600px) {
    .form-item {
      width: 100%; }
    .form-item--privacy_policy {
      padding-top: 0; } } }

