.capture-wrapper,
.capture-media-wrapper > div,
.capture-media-wrapper {
  display: flex;
  flex: 1; }

.capture-actions {
  margin: auto; }

.capture-action {
  display: block;
  border: none;
  background: #fff;
  border: 1px solid #fff;
  box-shadow: 0 2px 10px rgba(#000, .1);
  line-height: 20px;
  padding: 9px 15px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 500;
  color: $fontColor;
  text-align: center;
  width: 100%;
  i.fa {
    margin-right: 10px;
    color: $green;
    font-size: 16px; }
  &:hover {
    text-decoration: underline $green2; }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; } }

.capture-button-wrapper {
  display: inline-block;
  margin-right: 10px; }

.capture-button {
  font-size: 14px;
  display: inline-block;
  border: 1px solid $borderLighterGrey;
  padding: 6px 12px;
  line-height: 20px;
  border-radius: 20px;
  background: #fff;
  i.fa {
    margin-right: 10px;
    font-size: 12px;
    color: $fontColorLight; }
  &:hover {
    border-color: $orange;
    i.fa {
      color: $orange; } }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; }
  @media screen and (max-width: 330px) {
    font-size: 12px;
    font-weight: 500;
    i.fa {
      font-size: 10px; } } }

.capture-media {
  position: relative;
  background: #333;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  flex: 1;
  video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain; } }

.capture-media-action {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff; }

.capture-media-action--record {
  background: rgba(#000, .5); }

.capture-media-action--counting {
  background: none; }

.capture-media-action--permission {
  display: flex; }

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 2px rgba($red, .5); }
  100% {
    box-shadow: 0 0 0 20px rgba($red, 0); } }

.capture-media-action-button {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 15px;
  button {
    display: block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 50%;
    border: none;
    background: $red;
    padding: 0;
    margin: 0 auto;
    position: relative;
    &:hover {
      box-shadow: 0 0 0 6px rgba($red, .2); }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 6px rgba($red, .2); }
    .capture-media-action--stop & {
      background: #fff;
      color: $red;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 50%;
        animation: ripple 3s linear infinite 1s; }
      &:after {
        animation: ripple 3s linear infinite 2s; } }
    @media screen and (max-width: 400px) {
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 12px; } } }

.capture-media-countdown {
  font-size: 24px;
  font-weight: 700; }

.capture-media-action-title {
  font-size: 20px;
  line-height: 22px;
  font-weight: 300;
  margin: auto;
  text-align: center;
  @media screen and (max-width: 400px) {
    font-size: 20px;
    line-height: 26px; } }

.capture-media-close {
  position: absolute;
  top: 10px;
  right: 10px;
  button {
    display: block;
    height: 24px;
    width: 24px;
    border: none;
    border-radius: 50%;
    color: #fff;
    background: $red;
    font-size: 12px;
    margin: 0;
    padding: 0;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px #fff; }
    &:hover {
      background: #fff;
      color: $blue; } } }


.capture-media-error {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: tomato;
  color: #fff;
  display: flex;
  opacity: .8; }


.capture-media-error-inner {
  margin: auto;
  font-size: 18px; }
