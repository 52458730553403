.survey-share {
  padding: 0 20px 40px; }

.survey-share-title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500; }

.survey-share-buttons {
  text-align: center; }

.survey-share-button {
  display: inline-block;
  margin: 5px;
  padding: 10px 15px;
  background: $blue;
  height: 40px;
  text-align: center;
  line-height: 20px;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  i.fab {
    margin-right: 6px; }
  &:hover {
    opacity: .8; } }

.survey-share-button--twitter {
  background: #56acee; }

.survey-share-button--linkedin {
  background: #0077b5; }

.survey-share-button--google {
  background: #efefef;
  color: $fontColor;
  i.fab {
    color: #4285f4; } }
