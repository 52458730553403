@import "variables";

.q-checkbox,
.q-radio {
  display: flex;
  cursor: pointer;
  .q-checkbox-label {
    line-height: 20px; } }

button.q-checkbox,
button.q-radio {
  width: 100%;
  background: none;
  border: none;
  padding: 0;
  text-align: left;
  &:focus {
    outline: none; } }

.q-checkbox-icon-wrapper,
.q-radio-icon-wrapper {
  display: flex;
  width: 30px;
  min-height: 40px;
  background: none;
  border: none;
  padding: 0;
  vertical-align: middle;
  &:focus {
    outline: none; } }

.q-checkbox-icon,
.q-radio-icon {
  display: flex;
  width: 30px;
  height: 30px;
  border: 2px solid $borderLightGrey;
  border-radius: 3px;
  background: #fff;
  margin: auto;
  i {
    display: block;
    width: 12px;
    height: 12px;
    font-size: 13px;
    margin: auto;
    color: #fff; } }

.q-radio-icon {
  border-radius: 15px; }

.q-checkbox-label,
.q-radio-label {
  flex-grow: 1;
  line-height: 26px;
  padding-top: 7px;
  padding-left: 10px;
  .q-item-input--privacy_policy & {
    padding-top: 0; } }

.q-checkbox-label-required {
  margin-left: 5px;
  color: $requiredColor;
  // .q-checkbox:hover &
 }  //   color: $yellow

.q-checkbox-label-link {
  line-height: 20px;
  a {
    color: $fontColor;
    text-decoration-color: $fontColor;
    &:hover {
      text-decoration-color: $orange; }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $orange; } } }

.q-checkbox-wrapper--footnote {
  text-align: center;
  .q-checkbox {
    display: inline-flex;
    background: darken(#fff, 3%);
    padding: 5px 15px 5px 10px;
    border-radius: 20px; }
  .q-checkbox-icon-wrapper {
    min-height: 30px; }
  .q-checkbox-icon {
    width: 20px;
    height: 20px;
    border-width: 1px;
    border-radius: 2px;
    i {
      font-size: 11px; } }
  .q-checkbox-label {
    padding-top: 5px;
    padding-left: 5px;
    font-size: 14px;
    color: $fontColor;
    opacity: .8; } }
