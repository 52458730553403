@import "variables";

.q-video-intro {}


.q-video-intro-inner {}


.q-video-intro-header {
  text-align: center; }

.q-video-intro-header-t1 {
  font-size: 18px;
  line-height: 24px;
  color: #5a6374;
  i.fa {
    color: #40c9b2;
    margin-right: 10px; } }

.q-video-intro-header-t2 {
  max-width: 460px;
  margin: 15px auto 0;
  font-size: 30px;
  line-height: 32px;
  color: #364155; }

.q-video-intro-quote {
  position: relative;
  display: flex;
  min-height: 200px;
  img {
    position: absolute;
    top: 30px;
    left: 30px; }
  @media screen and (max-width: 330px) {
    top: 0;
    left: 0;
    img {
      transform: scale(.6); } } }


.q-video-intro-quote {
  max-width: 600px;
  margin: 40px auto 0;
  position: relative;
  border: 1px solid $borderLighterGrey;
  box-shadow: 0 2px 10px rgba(#000, .05);
  color: #364155;
  border-radius: 3px;
  padding: 0 20px;
  &:before {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    border: 16px solid transparent;
    border-top-color: #fff;
    z-index: 2;
    margin-left: -8px; }
  &:after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    border: 18px solid transparent;
    border-top-color: lighten($borderLighterGrey, 2%);
    margin-left: -10px; } }

.q-video-intro-comment {
  position: relative;
  z-index: 2;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  margin: auto;
  p {
    margin: 0 0 20px;
    &:last-child {
      margin: 0; } } }

.q-video-intro-comment--small {
  font-size: 16px;
  line-height: 20px; }

@keyframes thumbUp {
  0% {
    opacity: 0; }
  60% {
    transform: rotate(-6deg) translate3d(-2px, -5px, 0) scale(1.1);
    opacity: 0; }
  85% {
    transform: rotate(3deg) translate3d(3px, 5px, 0) scale(1.2);
    opacity: 1; }
  100% {
    transform: none; } }

.animate-thumb-up {
  animation: thumbUp 1s ease-in-out; }
