.page--default {
  .survey-form-inner,
  .survey-thanks-inner {
    padding: 30px 20px 20px 20px;
    max-width: 820px;
    margin: 0 auto;
    @media screen and (max-width: 600px) {
      padding: 10px; } } }

.survey-form-header,
.survey-thanks-header {
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 1px solid $borderLighterGrey;
  margin: 0 10px 20px;
  .page--frame & {
    display: none; } }

.survey-form-header-logo,
.survey-thanks-header-logo {
  img {
    border: 0;
    display: block;
    margin: 0 auto;
    width: 160px;
    height: 80px;
    object-fit: contain;
    @media screen and (max-width: 330px) {
      width: 100%;
      height: 100px; } } }

.survey-form-header-name,
.survey-thanks-header-name {
  font-size: 28px;
  font-weight: 300;
  display: inline-block;
  margin: 20px auto 10px; }


.page--default .survey-form-fields {
  margin: 20px 0 0;
  position: relative; }

.survey-form-loader {
  display: flex;
  width: 100%;
  height: 100%;
  background: rgba(#fff, .6);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999; }

.survey-loader {
  display: flex;
  height: 400px; }

.survey-loader i.fa,
.survey-form-loader i.fa, {
  display: block;
  width: 40px;
  height: 40px;
  color: rgba(#000, .6);
  font-size: 40px;
  line-height: 40px;
  margin: auto; }


.survey-notfound {
  display: flex;
  height: 400px; }

.survey-notfound-inner {
  margin: auto;
  text-align: center;
  max-width: 500px;
  i.fa {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 60px;
    color: #999; } }

.survey-notfound-text {
  font-size: 26px; }

// Error
.survey-error,
.messages {
  padding: 10px; }

.survey-error-message,
.message {
  padding: 10px;
  background: $red;
  color: #fff;
  box-shadow: inset 0 -1px 0 rgba(#000, .1);
  font-size: 14px;
  font-weight: 500;
  max-width: 780px;
  margin: 0 auto;
  border-radius: 3px;
  i.fa {
    margin-right: 6px;
    font-size: 12px;
    color: rgba(#000, .3); } }
