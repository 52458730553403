@import "variables";

.q-stars-wrapper {
  display: inline-block;
  margin-left: -6px; }

.q-stars {
  display: flex;
  flex-direction: row;
  justify-content: flex-end; }

.q-stars-item {
  background: none;
  border: none;
  color: $borderLightGrey;
  display: block;
  cursor: pointer;
  padding: 6px;
  text-align: center;
  border-radius: 3px;
  &.q-stars-item--selected,
  &.q-stars-item--under {
    color: $orange; }
  i.fa {
    font-size: 3em;
    @media screen and (max-width: 700px) {
      font-size: 2.6em; }
    @media screen and (max-width: 300px) {
      font-size: 1.8em; } }
  &:focus {
    outline: none;
    box-shadow: 0 0 2px 2px rgba(#159ce4, .7); } }

.q-stars-labels-text {
  margin-top: 16px;
  color: $fontColor;
  display: flex;
  flex-direction: row;
  line-height: 20px;
  padding: 0 6px;
  .form-fieldset & {
    margin-top: 10px; } }

.q-stars-labels-text > div {
  display: block;
  width: 50%; }

.q-stars-labels-text-max {
  text-align: right; }
