/**
 * Text
 */

.comment-text {
  background: rgba(#fff, .95);
  textarea {
    position: relative;
    z-index: 4; } }

.comment-text-input-user {
  display: flex;
  position: relative;
  z-index: 3;
  &:after {
    content: " ";
    display: block;
    width: 10px;
    height: 10px;
    background: $borderLightGrey;
    transform: rotate(45deg);
    position: absolute;
    bottom: calc(100% - 5px);
    left: 18px;
    .comment-text textarea:focus + & {
      background: $orange; } } }

.comment-text-input-user-icon {
  color: #fff;
  background: $orange;
  width: 26px;
  height: 26px;
  line-height: 26px;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
  margin-left: 10px; }

.comment-text-input-user-info {
  line-height: 18px;
  margin-top: 14px;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500; }


/**
 * Video
 */

$videoBgColor: rgba(darken(#fff, 1%), .97);

.comment--video {
  border: 2px solid $borderLightGrey;
  border-bottom: none;
  margin-top: 20px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background: darken(#fff, 1%);
  // background-image: linear-gradient(90deg, $videoBgColor, $videoBgColor), $iconWallBlack
  // background-size: 50%, 50%
 }  // background-position: center center, center center

.comment--video + .comment--text textarea {
  border-top: 1px solid $borderLighterGrey;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  resize: none;
  &:hover,
  &:focus {
    border-left-color: $borderLightGrey;
    border-right-color: $borderLightGrey;
    border-bottom-color: $borderLightGrey; }
  &:focus {
    border-bottom-color: $orange; } }

.comment-video-upload {
  position: relative;
  padding: 10px 15px; }

.comment-video-upload-text {
  margin-right: 5px;
  font-weight: 500;
  font-size: 14px;
  @media screen and (max-width: 480px) {
    display: block;
    margin-bottom: 10px; } }

.comment-video-upload-button {
  position: relative;
  font-size: 14px;
  display: inline-block;
  border: 1px solid $borderLighterGrey;
  padding: 10px 20px;
  line-height: 20px;
  border-radius: 20px;
  background: #fff;
  i.fa {
    margin-right: 10px;
    font-size: 12px;
    color: $fontColorLight; }
  @media screen and (max-width: 330px) {
    font-size: 12px;
    font-weight: 500;
    i.fa {
      font-size: 10px; } }
  &:hover {
    border-color: $orange;
    i.fa {
      color: $orange; } }
  input {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    top: 0;
    left: 0; } }

.comment-video-upload-button-icon {
  color: $orange;
  font-size: 40px;
  background: $lightGrey;
  width: 120px;
  height: 120px;
  line-height: 120px;
  border-radius: 50%;
  margin: 0 auto 30px; }

.comment-video {
  padding: 10px; }

.comment-video-title {
  text-align: center;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  padding: 20px; }

.comment-video-preview {
  max-width: 400px;
  margin: 0 auto;
  video {
    display: block;
    width: 100%;
    border-radius: 3px;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $yellow; } } }

.comment-video-actions {
  text-align: center;
  padding: 20px; }

.comment-video-action {
  display: inline-block;
  font-size: 14px;
  position: relative;
  border: none;
  background: none;
  padding: 5px 15px;
  line-height: 20px;
  border-radius: 15px;
  border: 1px solid $borderLighterGrey;
  background: #fff;
  margin: 0 10px;
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer; }
  i.fa {
    margin-right: 10px;
    font-size: 10px;
    position: relative;
    top: -1px;
    color: $yellow; }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; }
  &:hover {
    border-color: $yellow; }
  @media screen and (max-width: 415px) {
    margin: 5px 10px; } }

.comment-video-action--remove {
  i.fa {
    color: tomato; }
  &:hover {
    border-color: tomato; } }

.comment-video-action--reload {
  i.fa {
    color: $yellow; }
  &:hover {
    border-color: $yellow; }
  @media screen and (max-width: 600px) {
    margin-bottom: 10px; } }
