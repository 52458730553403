.video-field {
  display: flex;
  border-radius: 3px;
  box-shadow: 0 2px 10px rgba(#000, .1);
  @media screen and (max-width: 750px) {
    display: block; } }

.video-field-video {
  width: 400px;
  height: 300px;
  background: #333;
  flex: 0 0 400px;
  border-radius: 3px 0 0 3px;
  display: flex;
  @media screen and (max-width: 750px) {
    width: 100%;
    border-radius: 3px 3px 0 0; } }

.video-field-video--capture {}


.video-field-video--preview {
  video {
    display: block;
    width: 100%;
    height: 100%;
    flex: 1;
    object-fit: contain;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $yellow; } } }

.video-field-action {
  flex: 1;
  border: 1px solid $borderLighterGrey;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  display: flex;
  flex-direction: column; }

.video-field-action-buttons {
  padding: 0 20px 20px;
  @media screen and (max-width: 750px) {
    text-align: center; } }

.video-field-button {
  display: inline-block;
  background: none;
  border: none;
  padding: 0 6px;
  line-height: 20px;
  border-radius: 10px;
  color: $fontColorLight;
  font-size: 14px;
  i.fa {
    margin-right: 6px;
    font-size: 12px; }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; }
  &:hover {
    i.fa {
      color: tomato; } } }

.video-field-content {
  padding: 20px;
  color: $fontColor;
  h3 {
    font-size: 16px;
    margin: 0 0 5px;
    i.fa {
      margin-right: 6px;
      color: $green; } }
  p {
    margin: 0 0 10px;
    &:last-child {
      margin: 0; } }
  ul, ol {
    margin: 10px 0 0;
    padding: 0;
    list-style: none;
    li {
      margin-bottom: 5px;
      position: relative;
      padding-left: 14px;
      line-height: 20px;
      &:before {
        content: "-";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 14px;
        text-align: left;
        color: $fontColorLight;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px; } }
    & + h3 {
      margin-top: 20px; } }
  ol {
    counter-reset: list-counter;
    li {
      counter-increment: list-counter;
      padding-left: 20px;
      &:before {
        content: counter(list-counter) ". ";
        font-size: 12px;
        font-weight: 500;
        width: 20px;
        text-align: center;
        padding-top: 2px; } } } }

.video-field-video-upload {
  display: flex;
  flex: 1; }

.video-field-video-upload-button {
  position: relative;
  margin: auto;
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer; } }

.video-field-video-upload-button span {
  @extend .capture-action; }

.capture-action + .video-field-video-upload {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px dashed rgba(#fff, .1);
  .video-field-video-upload-button {
    display: block;
    width: 100%;
    span {
      color: #fff;
      background: none;
      border-color: $green2; } } }

.capture-action + .video-field-video-upload .video-field-video-upload-button:hover span {
  text-decoration: underline $green2; }
