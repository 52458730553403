.survey-langswitcher {
  // border-bottom: 1px solid $borderLighterGrey
  height: 40px; }

.survey-langswitcher-select {
  float: right;
  position: relative;
  button {
    display: block;
    border: none;
    background: none;
    text-align: center;
    width: 100%;
    padding: 10px 10px;
    line-height: 20px;
    font-size: 14px;
    min-width: 120px;
    &:focus {
      outline: none;
      box-shadow: inset 0 0 0 2px $yellow; }
    i.fa {
      font-size: 12px;
      margin-right: 4px; } } }

.survey-langswitcher-select button {
  color: rgba(#000, .8);
  i.fa {
    color: rgba(#000, .6); }
  &:hover,
  &:hover i.fa {
    color: rgba(#000, .8); }
  .survey-langswitcher--dark & {
    color: rgba(#fff, .8);
    i.fa {
      color: rgba(#fff, .6); }
    &:hover,
    &:hover i.fa {
      color: rgba(#fff, .8); } } }

.survey-langswitcher-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3; }

.survey-langswitcher .survey-langswitcher-menu,
.survey-langswitcher--dark .survey-langswitcher-menu {
  position: absolute;
  z-index: 4;
  top: 100%;
  right: 0;
  background: #fff;
  border: 1px solid $borderLightGrey;
  box-shadow: 0 3px 8px rgba(#000, .1);
  &:after {
    content: "";
    border: 6px solid transparent;
    border-bottom-color: $borderLightGrey;
    position: absolute;
    right: 50%;
    bottom: 100%;
    margin-left: -3px; }
  button {
    padding: 7px 10px;
    border-bottom: 1px solid $borderLighterGrey;
    color: $fontColor;
    &:last-child {
      border: none; }
    &:hover {
      background: darken(#fff, 2%);
      color: $fontColor; } } }
