.s-range-area {
  display: flex; }

.s-range-wrapper {
  flex: 1; }

.s-range {
  display: flex;
  flex-direction: row-reverse; }

.s-range-item {
  display: block;
  width: 100%;
  flex-grow: 1;
  padding: 0 5px;
  background: none;
  border: none;
  &:last-child {
    padding-left: 0; }
  &:first-child {
    padding-right: 0; }
  &:hover .s-range-item-button {
    border-color: $green2;
    background: #fff;
    .s-range-label {
      color: $fontColor; } }
  &:focus {
    outline: none;
    .s-range-item-button {
      box-shadow: 0 0 0 2px $green2;
      border-color: $green2; } } }

.s-range-item-button {
  display: block;
  width: 100%;
  border: 1px solid $borderLightGrey;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(#000, .07);
  padding: 10px;
  color: $fontColor;
  height: 40px;
  position: relative;
  .s-range-item--selected &,
  .s-range-item--selected:focus &,
  .s-range-item--selected:hover & {
    background: $green2;
    border-color: darken($green2, 15%);
    text-shadow: 1px 1px 1px rgba(#000, .1);
    .s-range-label {
      color: #fff; } }
  .s-range-item--over & {
    background: darken($lightGrey, 5%);
    .s-range-label {
      color: rgba(#222, .5); } }
  .s-range-no-opinion & {
 } }    // width: 40px


.s-range-labels-text {
  margin-top: 20px;
  color: $fontColor;
  display: flex;
  flex-direction: row-reverse;
  line-height: 20px;
  .form-fieldset & {
    margin-top: 10px; } }

.s-range-labels-text > div {
  display: block;
  width: 50%; }

.s-range-labels-text-max {
  text-align: right; }

.s-range-mobile-label {
  display: none;
  color: $fontColorLight;
  margin-left: 4px;
  &:before {
    content: "- ";
    opacity: .6; }
  .s-range-item--selected & {
    color: #fff; } }


.s-range-no-opinion {
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1px solid #efefef;
  width: 66px; }

.s-range-no-opinion-label {
  margin-top: 20px;
  position: relative;
  height: 20px; }

.s-range-no-opinion-label-text {
  font-size: 13px;
  font-weight: 500;
  line-height: 13px;
  text-align: center;
  color: $fontColorLighter;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%); }

.s-range-item-icon {
  display: inline-flex;
  width: 18px;
  height: 18px;
  background: #e7e7e7;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -9px 0 0 -9px;
  i.fa {
    margin: auto;
    font-size: 10px; }
  .s-range-item--selected & {
    background: #fff;
    border-color: #299381;
    color: #40c9b2; } }

@mixin foldedRange() {
  .s-range-no-opinion {
    padding: 10px 0 0;
    margin: 10px 0 0;
    border-left: none;
    border-top: 1px solid #efefef;
    width: 100%; }
  .s-range-area {
    display: block; }
  .s-range-labels-text,
  .s-range-no-opinion-label {
    display: none; }
  .s-range-mobile-label {
    display: inline-block; }
  .s-range {
    display: block; }
  .s-range-item {
    padding: 0 0 5px;
    &:last-child {
      padding: 0; } }
  .s-range-item-button {
    text-align: left;
    padding: 10px 15px;
    font-size: 15px; }
  .s-range-item--icon .s-range-item-button {
    padding-left: 30px;
    .s-range-mobile-label:before {
      content: ""; } }
  .s-range-item-icon {
    left: 10px;
    margin: -9px 0 0 0; } }

.form-fieldset--columns-2,
.form-fieldset--columns-3,
.form-fieldset--columns-4 {
  @include foldedRange(); }

@media screen and (max-width: 600px) {
  @include foldedRange(); }

